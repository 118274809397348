import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.png";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">

          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <div className="social-icon">
                <a
                  href="https://www.linkedin.com/in/dream-25in"
                  target="_blank"
                >
                  <img src={navIcon1} alt="" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100075737985725&sk=about"
                  target="_blank"
                >
                  <img src={navIcon2} alt="" />
                </a>
                <a href="https://wa.me/+916289761298" target="_blank">
                  <img src={navIcon3} alt="" />
                </a>
              </div>
            </div>
            <p>Copyright 2023-24. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
